<template>
	<!-- <article class="report-list">
		<figure
			class="report-list-element report-list__image-container d-flex flex-row align-center"
		>
			<img class="report-list__image" :src="imageUrl" alt="" />
		</figure>
		<p class="report-list-element report-list__username primary-text">
			{{ userName }}
		</p>
		<p class="report-list-element report-list__name primary-text">
			{{ courseName }}
		</p>
		<p
			class="report-list-element report-list__date row-data-date--left primary-text"
		>
			{{ date }}
		</p>
		<figure class="report-list__buttons-container">
			<slot name="buttons"></slot>
		</figure>
		<certificate-template
			v-if="displayPopup === true"
			:userName="userName"
			:courseTitle="courseName"
			:completed="completed"
			:dateCompleted="dateCompleted"
			@closePopup="$emit('closePopup')"
		>
		</certificate-template>
	</article> -->
<tr class="align-middle">
		<td>
			<img class="row-data-image row-thumnail-border" v-if="imageUrl" :src="imageUrl	">
		</td>
		<td class="font-weight-bold">{{ userName }}</td>
		<td><b>{{ courseName }}</b></td>
		<td>{{ date }}</td>
		<td class="text-right">
			<slot name="buttons"></slot>
			<certificate-template
			v-if="displayPopup === true"
			:userName="userName"
			:courseTitle="courseName"
			:completed="completed"
			:dateCompleted="dateCompleted"
			@closePopup="$emit('closePopup')"
		>
		</certificate-template>
		</td>
	</tr>
</template>

<script>
import CertificateTemplate from "@/views/common/CertificateTemplate.vue";

export default {
	name: "ReportRowData",
	components: { CertificateTemplate },
	props: ["certificate", "dateType", "displayPopup"],
	computed: {
		imageUrl() {
			return "";
		},
		userName() {
			if (!!this.certificate?.course?.title === false) return;
			return this.certificate?.user?.name;
		},
		courseName() {
			if (!!this.certificate?.course?.title === false) return;
			return this.certificate?.course?.title;
		},
		created() {
			if (!!this.certificate?.created_at === false) return;
			return `Date created ${new Date(
				this.certificate?.created_at.slice(0, 10)
			).toLocaleDateString()}
			`;
		},
		date() {
			return this[this.dateType];
		},
		completed() {
			if (!!this.certificate?.is_completed === false) return;
			return this.certificate?.is_completed;
		},
		dateCompleted() {
			if (!!this.certificate?.completed_at === false) return;
			return `Data Finished: ${new Date(
				this.certificate?.completed_at.slice(0, 10)
			).toLocaleDateString()}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.report-list-element {
	width: 25%;
}

.report-list__buttons-container {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
</style>
